import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import './Login.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const auth = getAuth();
  const navigate = useNavigate();

  const handleSignIn = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
        .then(() => {
            navigate('/home'); // Changed from navigate('/') to navigate('/home')
        })
        .catch((error) => {
            alert(`Sorry! Those credentials do not match our records.`);
            console.error('Error signing in:', error);
        });
  };

  return (
    <div className="signin-container">
      <div className="left-column">
        <h2 className="sign-in-text">Welcome to PeerSupport.io Web Chat</h2>
        <form onSubmit={handleSignIn}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="email-input"
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="email-input"
          />
          <button type="submit" className="sign-in-button">Login</button>
        </form>
      </div>
      <div className="right-column"></div>
    </div>
  );
};

export default Login;
