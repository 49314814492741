// Sidebar.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Sidebar.css';
import { getAuth, signOut } from 'firebase/auth';
import { useAppState } from '../AppStateContext'; // Import the useAppState hook

function Sidebar() {
  const [activeConversation, setActiveConversation] = useState(null);
  const navigate = useNavigate();
  const auth = getAuth();
  const { setSelectedConversation } = useAppState(); // Destructure setSelectedConversation from the context

  // An array representing conversations
  const conversations = [
    { id: 1, name: "River" },
    { id: 2, name: "Blake" },
    { id: 3, name: "Avery" }
  ];

  // Function to handle clicking on a conversation link
  const handleConversationClick = (conversation) => {
    setSelectedConversation(conversation.name); // Set the selected conversation in the global state
    setActiveConversation(conversation.id); // Set the active conversation in the local state
    // Add any additional logic that should happen when a conversation is selected
  };

  const handleLogout = () => {
    signOut(auth).then(() => {
      navigate('/login');
    }).catch((error) => {
      console.error('Error logging out:', error);
    });
  };

  return (
    <div className="sidebar">
      <h4 className="sidebar-title">Messages</h4>
      <hr className="sidebar-divider"></hr>
      <div className="sidebar-links">
        {conversations.map(conversation => (
          <a key={conversation.id}
             href="#"
             className={`sidebar-link ${activeConversation === conversation.id ? 'active' : ''}`}
             onClick={() => handleConversationClick(conversation)}>
            {conversation.name}
          </a>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
